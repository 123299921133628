<!--
 * @Description: 财务预审
 * @Author: transparent
 * @Date: 2022-02-21 13:48:22
 * @LastEditTime: 2022-08-10 18:00:26
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/costManagementFinancialStaff.vue
-->
<template>
  <div>
    <el-main>
      <el-form
        :model="params"
        ref="costManagementForm"
        label-width="120px"
        class="fromStyle"
      >
        <el-form-item label="日程" prop="refId">
          <el-select
            v-model="params.refId"
            @visible-change="getCalendarScheduleSelectList($event)"
            filterable
            placeholder="请选择日程"
            clearable
            :no-data-text="
              !calendarScheduleSelectList ? '加载中···' : '暂无数据'
            "
          >
            <el-option
              v-for="item in calendarScheduleSelectList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
              {{ item.title }}
              <span class="workflowVersion">{{
                item.ownerId == userId ? "我的" : item.ownerName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行程" prop="title">
          <el-select
            v-model="params.title"
            @visible-change="getScheduleTriplist($event)"
            filterable
            placeholder="请选择行程"
            clearable
            :no-data-text="!scheduleTriplist ? '加载中···' : '暂无数据'"
          >
            <el-option
              v-for="(item, index) in scheduleTriplist"
              :key="index"
              :value="item.fromCity + '->' + item.toCity"
            >
              <span>{{ item.fromCity }}->{{ item.toCity }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报销申请人" prop="userId">
          <el-select
            v-model="params.userId"
            @visible-change="findMyProjectWorkflowMembers($event)"
            filterable
            placeholder="请选择报销申请人"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in myProjectWorkflowMembers"
              :key="item.uid"
              :label="item.real_name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="费用发生时间" prop="searchDate">
          <el-date-picker
            v-model="params.searchDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="timestamp"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="最新进展状态" prop="preApplyStatus">
          <el-select
            v-model="params.preApplyStatus"
            filterable
            placeholder="请选择最新进展状态"
            clearable
            no-data-text="加载中···"
            @change="preApplyStatusChange($event)"
          >
            <el-option
              v-for="item in preApplyStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="isConfirm" style="width: 20% !important">
          <el-checkbox
            v-model="params.isConfirm"
            :true-label="1"
            :false-label="null"
            @change="isConfirmChange"
            >已自我确认</el-checkbox
          >
        </el-form-item>
        <el-form-item label="费用ID" prop="ID">
          <el-input
            type="text"
            v-model="params.id"
            placeholder="请输入费用ID"
            clearable
          ></el-input>
        </el-form-item>
        <div style="margin-left: 80px; float: right">
          <el-button @click="resetConditions">重置</el-button>
          <el-button type="primary" @click="openDialog('search')"
            >查询</el-button
          >
          <el-button
            type="primary"
            @click="openDialog('batch')"
            v-permission="['admin:costManagementFinancialStaff:batch']"
            >批量审核</el-button
          >
        </div>
      </el-form>

      <!-- 表格 -->
      <el-table
        border
        ref="multipleTable"
        :data="tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :selectable="selectAble">
        </el-table-column>
        <el-table-column label="日程" prop="refName">
          <template slot-scope="scope">
            <span class="refName" @click="openScheduleDetails(scope.row.refId)">
              <el-link>{{ scope.row.refName }}</el-link></span
            >
          </template>
        </el-table-column>

        <el-table-column label="出行人员" prop="userName">
          <template slot-scope="scope">
            <span>
              {{ scope.row.userName }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="最新进展状态" prop="preApplyStatus">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.preApplyStatus == 2"
              placement="top"
              title="钉钉审核状态"
              width="200"
              trigger="click"
            >
              <div>{{ scope.row.formalApplyStatus | formalApplyStatus }}</div>
              <span class="preApplyStatus" slot="reference">
                {{ scope.row.preApplyStatus | preApplyStatus }}
              </span>
            </el-popover>
            <span v-else>
              {{ scope.row.preApplyStatus | preApplyStatus }}
            </span>
          </template>
        </el-table-column>
        <!-- formalApplyStatus -->
        <el-table-column
          label="最新进展说明"
          prop="latestRemark"
        ></el-table-column>
        <el-table-column label="最新进展时间" prop="latestRemarkTime">
          <template slot-scope="scope">
            <span>{{ scope.row.latestRemarkTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="申请人是否确认" prop="isConfirm">
          <template slot-scope="scope">
            <span>{{ scope.row.isConfirm | isConfirm }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="170">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('check', scope.row)"
            >
              查看
            </el-button>
            <el-button
              :type="
                scope.row.relatedExpenseInfo.list.length >
                scope.row.relatedExpenseInfo.confirmedCount
                  ? 'info'
                  : 'primary'
              "
              size="mini"
              @click="openDialog('audit', scope.row)"
              v-permission="['admin:costManagementFinancialStaff:audit']"
              :disabled="!selectAble(scope.row)"
              >{{ scope.row.preApplyStatus == 3 ? "财务再审" : "财务预审" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </el-main>
    <financialAudit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :id="costId"
      :detail="detail"
      :batchInfo="batchInfo"
    ></financialAudit>
    <!-- 日程详情 -->
    <calendarDetials
      v-if="scheduleDetailsDialog"
      :detailsDialog="scheduleDetailsDialog"
      :detailsData="selectThingsDetails"
      v-on:listenScheduleDetails="listenScheduleDetails"
    ></calendarDetials>
  </div>
</template>

<script>
import {
  getFinancialExpenseList,
  getScheduleTriplist,
} from "@/api/costManagement";
import financialAudit from "../components/costManagement/financialAudit";
import { formatDate } from "@/common/date";
import {
  getCalendarScheduleSelectList,
  getCalendarThingDetails,
} from "@/api/calendar";
import { findMyProjectWorkflowMembers } from "@/api/myMission";
import calendarDetials from "@/components/costManagement/calendarDetails";

export default {
  name: "costManagementFinancialStaff",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      addDialog: false,
      typeDialog: "",
      roleCode: "",
      costId: null,
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      multipleSelection: [],
      detail: null,
      calendarScheduleSelectList: null,
      scheduleTriplist: null,
      myProjectWorkflowMembers: null,
      feeTypeList: null,
      params: {
        preApplyStatus: "1",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      batchInfo: null,
      userId: window.localStorage.getItem("userId"),
      preApplyStatusList: [
        {
          value: "0",
          label: "填报中",
        },
        {
          value: "1",
          label: "待财务预审",
        },
        {
          value: "2",
          label: "财务预审通过",
        },
        {
          value: "3",
          label: "财务审核拒绝",
        },
      ],
      scheduleDetailsDialog: false,
      selectThingsDetails: null,
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    preApplyStatus(value) {
      switch (value) {
        case 0:
          return "填报中";
        case 1:
          return "待财务预审";
        case 2:
          return "财务预审通过";
        case 3:
          return "财务审核拒绝";
      }
    },
    formalApplyStatus(value) {
      switch (value) {
        case 0:
          return "暂无状态";
        case 1:
          return "申请中";
        case 2:
          return "审核通过";
        case 3:
          return "审核拒绝";
      }
    },
    isConfirm(value) {
      switch (value) {
        case 0:
          return "未确认";
        case 1:
          return "已确认";
      }
    },
  },
  components: {
    financialAudit,
    calendarDetials,
  },
  activated() {
    this.queryExpenseList(1, this.pageInfo.pageSize);
  },
  mounted() {
    // this.queryExpenseList(1, this.pageInfo.pageSize);
    this.params.isConfirm = 1;
  },

  methods: {
    preApplyStatusChange(event) {
      console.log(event);
      if (event == 3) {
        this.params.isConfirm = 0;
      }else {
        this.params.isConfirm = 1;
      }
      this.$forceUpdate();

    },
    //监听详情弹窗关闭
    listenScheduleDetails(params) {
      console.log(params);
      let { close, type } = params;
      this.scheduleDetailsDialog = !close;
    },
    //获取日程详情
    openScheduleDetails(id) {
      getCalendarThingDetails(id).then((response) => {
        this.selectThingsDetails = response.data;
        this.scheduleDetailsDialog = true;
      });
    },
    isConfirmChange() {
      this.$forceUpdate();
    },
    selectAble(row, index) {
      if (row.preApplyStatus == 1) {
        return true;
      } else {
        return false;
      }
    },
    resetConditions() {
      this.$refs.costManagementForm.resetFields();
      this.params.endDate = null;
      this.params.startDate = null;
      this.params.searchDate = null;
      this.params.isConfirm = null;
      this.params.id = null;
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.queryExpenseList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      this.$forceUpdate();
    },
    async selectListDict(cb, type) {
      if (cb) {
        let info = { group: type };
        const list = await selectListDict(info);
        this.feeTypeList = list.data;
      }
    },
    getScheduleTriplist(cb) {
      if (cb) {
        if (!this.params.refId) {
          this.$message({ type: "warning", message: "请选择日程!" });
          return false;
        }
        getScheduleTriplist(this.params.refId).then((res) => {
          if (res.success) {
            this.scheduleTriplist = res.data;
          }
        });
      }
    },
    //1
    findMyProjectWorkflowMembers(cb) {
      if (!cb) return;

      let param = {};
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;
        }
      });
    },
    //
    getCalendarScheduleSelectList(cb) {
      console.log(cb);
      if (cb) {
        let info = { mode: 3, officeModeList: ["3", "4"] };
        getCalendarScheduleSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;
            this.calendarScheduleSelectList = list;
          }
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      this.queryExpenseList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.queryExpenseList(val, this.pageInfo.pageSize);
    },
    queryExpenseList(num, pageSize) {
      let param = {
        ...this.params,
      };
      param.pageNum = num;
      param.pageSize = pageSize;
      getFinancialExpenseList(param).then((response) => {
        this.tableData = response.data;
        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },
    openButtonControlDialog(code) {
      this.roleCode = code;
      this.buttonControlDialog = true;
    },
    //新增编辑弹窗显示
    openDialog(type, row) {
      let that = this;
      this.typeDialog = type;
      if (type == "audit") {
        // this.detail = row;
        this.batchInfo = [row];
        this.editDialog = true;
      } else if (type == "search") {
        if (this.params.searchDate) {
          this.params.startDate = this.params.searchDate[0];
          this.params.endDate = this.params.searchDate[1];
        }
        this.queryExpenseList(1, this.pageInfo.pageSize);
      } else if (type == "batch") {
        if (that.multipleSelection.length == 0) {
          that.$message({ type: "warning", message: "请选择费用!" });
          return false;
        } else {
          this.batchInfo = JSON.parse(JSON.stringify(that.multipleSelection));
          this.editDialog = true;
        }
      } else if (type == "check") {
        this.batchInfo = [row];
        this.editDialog = true;
      }
      //
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.queryExpenseList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fromStyle {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .el-form-item {
    // display: flex;
    width: 32% !important;
    float: left;
    >>> .el-select {
      width: 100% !important;
    }
    >>> .el-date-picker {
      width: 100% !important;
    }
  }
}
.el-select {
  width: 100% !important;
}
.preApplyStatus {
  cursor: pointer;
}
</style>
