<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-02-18 14:50:44
 * @LastEditTime: 2022-08-11 22:47:34
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/costManagement/financialAudit.vue
-->


<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editDialog"
    center
    @close="dialogClose"
    width="1200px"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <el-form>
      <el-row type="flex" justify="end">
        <el-form-item> </el-form-item>
      </el-row>
    </el-form>
    <div class="bf" v-if="batchList">
      <div class="batchInfo" v-for="(item, index) in batchList" :key="index">
        <div class="left flexJfAc">
          <div class="index">{{ index + 1 }}.</div>
          <el-descriptions direction="horizontal" :column="1" v-if="item">
            <el-descriptions-item label="日程">{{
              item.refName
            }}</el-descriptions-item>
            <el-descriptions-item label="申请人">{{
              item.userName
            }}</el-descriptions-item>
            <el-descriptions-item label="自主确认时间">
              <span v-show="item.updateDate">
                {{ item.updateDate | formatDateFilter }}
              </span>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <!-- 表格 -->
        <el-table
          border
          ref="multipleTable"
          :data="item.tableData"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="45"> </el-table-column> -->
          <el-table-column label="日程" prop="refName"> </el-table-column>
          <el-table-column label="行程" prop="title"> </el-table-column>
          <el-table-column label="费用类型" prop="feeType">
            <template slot-scope="scope">
              <span>{{ scope.row.feeTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="费用名称" prop="feeTitle">
            <template slot-scope="scope">
              <span>{{ scope.row.feeTitle }}</span>
            </template>
          </el-table-column>
          <el-table-column label="费用发生时间" prop="feeStartDate" width="160">
            <template slot-scope="scope">
              <span> {{ scope.row.feeStartDate | formatDateFilter }} </span>
            </template>
          </el-table-column>
          <el-table-column label="申请金额" prop="feeAmount">
            <template slot-scope="scope">
              <el-tooltip
                v-show="scope.row.feeStandard"
                class="item"
                effect="dark"
                :content="'标准：¥' + scope.row.feeStandard || '暂无'"
                placement="top"
              >
                <span
                  :class="{
                    feeAmount: scope.row.feeAmount > scope.row.feeStandard,
                  }"
                >
                  {{ scope.row.feeAmount }}
                </span>
              </el-tooltip>
              <span v-show="!scope.row.feeStandard">{{
                scope.row.feeAmount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="费用关联人" prop="userName">
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.relatedUserList"
                :key="index"
                class="fileList"
              >
                <div v-if="item.userName">
                  {{ index + 1 }}.{{ item.userName }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="是否替票" prop="isSubstituteTicket">
            <template slot-scope="scope">
              <span>
                {{ scope.row.isSubstituteTicket | isSubstituteTicket }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="附件" prop="fileList" width="170">
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.fileList"
                :key="index"
                class="fileList"
              >
                <el-link @click="handleExport(item)">
                  <p v-if="item.originalFileName">
                    {{ index + 1 }}.{{ item.originalFileName }}
                  </p>
                  <p v-else>{{ index + 1 }}.{{ item.name }}</p>
                </el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="核报金额" prop="settleAmount">
            <template slot-scope="scope">
              <el-input
                :disabled="type == 'check'"
                type="number"
                :precision="2"
                :step="0.01"
                :controls="false"
                :class="{
                  settleAmount:
                    scope.row.settleAmount > scope.row.feeStandard &&
                    scope.row.feeStandard > 0,
                }"
                v-model="scope.row.settleAmount"
                @change="settleAmountChange($event, index)"
              ></el-input>
            </template>
          </el-table-column>
        </el-table>

        <div class="total flexFcAFs">
          <div class="flexJfAc" v-show="item.totalCost">
            <span class="title">总费用：¥</span>
            <span>{{ item.totalCost }}</span>
          </div>
          <div class="flexJfAc" v-show="item.settleAmount">
            <span class="title">核报总费用：¥</span
            ><span>{{ item.settleAmount }}</span>
          </div>
        </div>
      </div>
    </div>

    <el-form
      :model="formData"
      ref="financialAudit"
      :rules="rules"
      label-width="120px"
      v-show="type != 'check'"
    >
      <el-form-item label="审核意见" prop="preApplyStatus">
        <el-radio-group v-model="formData.preApplyStatus">
          <el-radio :label="2">同意</el-radio>
          <el-radio :label="3">拒绝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="latestRemark">
        <el-input
          type="textarea"
          maxlength="500"
          :autosize="{ minRows: 3 }"
          show-word-limit
          v-model="formData.latestRemark"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" v-show="type != 'check'">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" slot="reference" @click="sure"
        >提交审批意见</el-button
      >
    </div>

    <!-- 文件预览 -->
    <el-dialog
      title="文件预览"
      center
      :visible.sync="previewDialogVisible"
      @close="closePreviewDialog"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
      append-to-body
    >
      <div class="imgUrl" v-if="previewImgUrl">
        <el-image ref="imgUrl" :src="previewImgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePreviewDialog">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  expenseConfirm,
  expenseBatchConfirm,
  deleteExpenseDetail,
  expenseAddAttachments,
  getExpenseDetailList,
  expenseNoticeFinancial,
  expensePreApprove,
  expenseBatchPreApprove,
} from "@/api/costManagement";
import { selectListDict } from "@/api/dataManagement";
import { formatDate } from "@/common/date";
import { findMyProjectWorkflowMembers } from "@/api/myMission";
import editExpenseDetail from "@/components/costManagement/editExpenseDetail";

export default {
  name: "financialAudit",
  data() {
    return {
      title: "财务审核",
      token: null,
      formData: {
        id: null,
        preApplyStatus: 2,
        latestRemark: null,
        detailList: [],
      },
      formList: [],
      rules: {
        preApplyStatus: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        latestRemark: [
          { required: true, message: "请输入审核意见", trigger: "blur" },
        ],
      },

      //
      tableData: [],
      multipleSelection: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      calendarScheduleSelectList: [],
      myProjectWorkflowMembers: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      expenseDetail: null,
      expenseDetailDialog: false,
      expenseDetailType: null,
      feeTypeList: [],
      expenseDetailId: null,
      popoverVisible: false,
      batchList: null,
      previewDialogVisible: false,
      previewImgUrl: null,
      totalCost: null,
      settleAmount: null,
    };
  },
  props: {
    type: String,
    editDialog: Boolean,
    id: String,
    detail: Object,
    batchInfo: Array,
  },
  components: {
    editExpenseDetail,
  },
  filters: {
    formatDateFilter(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    isSubstituteTicket(value) {
      switch (value) {
        case 0:
          return "否";
        case 1:
          return "是";
      }
    },
  },
  computed: {},
  created() {
    if (this.type == "check") {
      this.title = "费用明细";
    }
  },
  mounted() {
    this.selectListDict("expense_detail_type");
    // this.getExpenseDetailList();
  },
  watch: {
    batchInfo: {
      handler(newVal, oldVal) {
        let that = this;
        if (!newVal) return;
        this.batchList = JSON.parse(JSON.stringify(this.batchInfo));
        this.batchList.map(function (item, index) {
          if (!item.hasOwnProperty("tableData")) {
            item.tableData = [];
            that.getExpenseDetailList(item.id, index);
          } else {
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    settleAmountChange(event, index) {
      let settleAmountList = [];
      let list = JSON.parse(JSON.stringify(this.batchList[index].tableData));
      settleAmountList = list.map((x) => Number(x.settleAmount));
      let totalsettleAmount = eval(settleAmountList.join("+"));
      console.log(settleAmountList);
      this.batchList[index].settleAmount = Number(totalsettleAmount).toFixed(2);
    },
    //
    handleExport(item) {
      let fileType = item.url.split(".")[item.url.split(".").length - 1];
      // let file =  {
      //   fileName:item.name,
      //   ...item
      // }
      // this.dialogClose()
      this.$router.push({
        name: "officeOnLine",
        params: { file: item, url: item.url, fileType: fileType },
      });

      return false;
      console.log("fileType: ", fileType);
      console.log("fileItem: ", item);
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        console.log(item.url);
        let url = encodeURIComponent(item.url);
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else if (fileType == "pdf") {
        console.log(item.url);
        this.imgUrl = null;
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + item.url;
        window.open(url);
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif"
      ) {
        this.previewPdfUrl = null;
        this.previewImgUrl = item.url;
        this.previewDialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(item.url);
      }
    },
    closePreviewDialog() {
      this.previewImgUrl = null;
      this.previewDialogVisible = false;
    },
    //
    async getExpenseDetailList(id, index) {
      let that = this;
      let res = await getExpenseDetailList({ expenseId: id });
      let list = res.data;

      list.map(function (item, index) {
        //1
        // if (item.settleAmount && item.settleAmount > 0) {
        //   item.settleAmount = item.settleAmount;
        //   return false;
        // }else if (item.feeStandard && item.feeStandard > 0) {
        //   if (item.feeAmount > item.feeStandard) {
        //     item.settleAmount = JSON.parse(JSON.stringify(item.feeStandard));
        //   } else {
        //     item.settleAmount = JSON.parse(JSON.stringify(item.feeAmount));
        //   }
        // } else {
        //   item.settleAmount = JSON.parse(JSON.stringify(item.feeAmount));
        // }
        //2

        if (that.type == "check") {
          if (that.batchInfo[0].formalApplyStatus == 1) {
            if (item.settleAmount && item.settleAmount > 0) {
              item.settleAmount = item.settleAmount;
            }
          } else {
            if (item.settleAmount == 0) {
              item.settleAmount = null;
            }
          }
        } else {
          if (item.settleAmount && item.settleAmount > 0) {
            item.settleAmount = item.settleAmount;
            return false;
          } else {
            item.settleAmount = JSON.parse(JSON.stringify(item.feeAmount));
          }
        }

        item.fileList = JSON.parse(item.attachments);
      });

      let feeAmountList = [];
      let settleAmountList = [];
      if (list.length > 0) {
        feeAmountList = list.map((x) => x.feeAmount);
        let totalCost = eval(feeAmountList.join("+"));
        that.batchList[index].totalCost = Number(totalCost).toFixed(2);
        settleAmountList = list.map((x) =>
          x.settleAmount == null ? 0 : x.settleAmount
        );
        console.log(settleAmountList);
        let totalsettleAmount = eval(settleAmountList.join("+"));
        that.batchList[index].settleAmount =
          Number(totalsettleAmount).toFixed(2);
      }

      // this.batchInfo[index].tableData = []
      that.batchList[index].tableData = list;

      that.$forceUpdate();
    },

    deleteExpenseDetail(row, index) {
      console.log(index);
      this.$confirm("确定要删除费用：" + row.title + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteExpenseDetail({ id: row.id }).then((response) => {
            if (response.success) {
              this.tableData.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async selectListDict(type) {
      if (!this.feeTypeList) {
        let info = { group: type };
        const list = await selectListDict(info);
        this.feeTypeList = list.data;
      }
    },

    handleSizeChange(val) {
      // this.queryExpenseList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      // this.queryExpenseList(val, this.pageInfo.pageSize);
    },
    findMyProjectWorkflowMembers(cb) {
      if (!cb) return;

      let param = {};
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;
        }
      });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //弹窗关闭
    dialogClose() {
      this.$emit("listenEditClose", { type: "cancel", isClose: true });
    },

    //确定
    sure() {
      let that = this;
      that.formList = [];
      that.$refs["financialAudit"].validate((valid) => {
        if (valid) {
          //
          let batchList = JSON.parse(JSON.stringify(that.batchList));
          batchList.forEach(function (item, index) {
            console.log(index);
            console.log(that.formList[index]);
            if (!that.formList[index]) {
              that.formList[index] = {};
              that.formList[index].detailList = [];
            }
            that.formList[index].id = item.id;
            that.formList[index].preApplyStatus = that.formData.preApplyStatus;
            that.formList[index].latestRemark = that.formData.latestRemark;
            // let detailList;
            item.tableData.map(function (child, index2) {
              //  if (!that.formList[index2] ) {
              //   that.formList[index2] = {};
              // }
              if (!that.formList[index].hasOwnProperty("detailList")) {
                that.formList[index].detailList = [];
              }
              that.formList[index].detailList.push({
                id: child.id,
                settleAmount: Number(child.settleAmount),
              });
            });
          });
          that.expenseBatchPreApprove();
        } else {
          console.log("-------------error");
        }
      });
    },
    expenseBatchPreApprove() {
      expenseBatchPreApprove(this.formList).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: "提交审批意见成功!",
          });
          this.$emit("listenEditClose", { type: "sure", isClose: true });
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}

.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 100%;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .el-input {
      /deep/ input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      /deep/ input[type="number"] {
        -moz-appearance: textfield !important;
      }
    }
  }
}
.dialog-footer {
  margin-top: 20px;
}

.settleAmount {
  /deep/ .el-input__inner {
    color: #f56c6c;
  }
}
.feeAmount {
  color: #f56c6c;
}
.batchInfo {
  margin-bottom: 20px;
  .left {
    .index {
      align-self: baseline;
      line-height: 1.3;
      font-weight: bold;
    }
  }
}
.fileList {
}

.total {
  margin: 20px 0 0 20px;
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  div {
    .title {
      // display: inline-block;
      min-width: 130px;
      text-align: right;
    }
  }
}
</style>